import React from 'react';
import '../Assets/css/SliderHero.css';
import HomeBannerRight from "../Assets/images/home/HomeBannerRight.png";
import Smile from "../Assets/images/home/Smile.png";
import Smile1 from "../Assets/images/home/Smile1.png";
import Slider from 'react-slick';
import { motion } from "framer-motion"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cloud from "../Assets/images/home/cloud.png";
const SliderHero = (props) => {
  const { homeData, companyData } = props
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: false,
          arrows: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          initialSlide: 2,
          arrows: false,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          arrows: false,
        }
      }
    ]
  };

  return (

    <>
      <div className="slider CustomContainer" style={{ backgroundImage: `url("${process.env.REACT_APP_IMAGE_URL}/${homeData.banner_details.bg_image}")` }}>
        <div className='innerSlider heroLeft'>
          <h1 className='' style={{
            lineHeight: "65px",
            textShadow: "3px 3px #222"
          }}>{homeData.banner_details.title}</h1>
          <p className='heroSliderDescription fs-6' dangerouslySetInnerHTML={{ __html: homeData.banner_details.short_description }}>

          </p>
          <div className="sliderContainer mt-4">
            <h5 style={{
              textShadow: "2px 2px #222"
            }} className='text-white'>Our Tie Up Partners</h5>
            <Slider {...settings}>
              {
                companyData.map((ele, id) => {
                  return (
                    <div key={id} className='sliderImageContainer'>
                      <img className='sliderImage' src={`${process.env.REACT_APP_IMAGE_URL}/${ele.company.thumbnail}`} alt={ele.company.thumbnail_alt_tag} />
                    </div>
                  )
                })
              }
            </Slider>
          </div>
        </div>
        <div className='heroRight'>
          <div className='heroRightImage'>
            {/* <img className='img-fluid' src={HomeBannerRight} alt="toy" /> */}
          </div>
        </div>
        {/* <div id="emojiOne"><img src={rocket} /></div> */}
        <div id="emojiTwo"><img src={Smile} /></div>
        <div id="emojiThree"><img src={cloud} /></div>

        {/* <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul> */}
      </div >
    </>

  )
}

export default SliderHero