import React, { useState, useEffect } from "react";
import "../Assets/css/ProductDescription.css";
import Breadcrumbs from "../Components/Breadcrumbs";
import RelatedProduct from "../Components/RelatedProduct";
import Subcription from "../Layouts/Subcription";
import AdsSliderImage1 from '../Assets/images/home/ProductDescription.jpg'
import HeaderPageBanner from "../Components/HeaderPageBanner";
import { Helmet } from "react-helmet";
import {
    FacebookShareButton,
    InstapaperShareButton,
    PinterestShareButton,
    WhatsappShareButton,
    TwitterShareButton
} from "react-share";
import {
    FacebookIcon,
    InstapaperIcon,
    PinterestIcon,
    TwitterIcon,
    WhatsappIcon,
} from "react-share";
const ProductDescription = (props) => {
    const productData = props.ProductData;
    const allProductData = props.allProductData;
    const [initialImg, setInitialImg] = useState(`${process.env.REACT_APP_IMAGE_URL}/${productData.thumbnail}`)
    const [initialId, setInitialId] = useState(0)
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setInitialImg(`${process.env.REACT_APP_IMAGE_URL}/${productData.thumbnail}`)
    }, [productData])

    const handleImageChange = (ele, id) => {
        console.log(ele);
        setInitialImg(`${process.env.REACT_APP_IMAGE_URL}/${ele}`)
        setInitialId(id)
    }


    const [filterData, setFilterData] = useState([])

    useEffect(() => {
        // console.log("filter Val : ", val);
        const returnFilterData = allProductData.filter(ele => ele.brand_name === productData.brand_name)
        setFilterData(returnFilterData)
    }, [productData.brand_name])


    return (
        <>
            <Helmet>
                <title>{`${productData.meta_title} | ${productData.brand_name} | Products | MAXWELL (INDIA) is one of the oldest and reputed distributors of Toys and Games in Kolkata`}</title>
                <meta name="description" content={productData.meta_description} />
                <meta name="keywords" content={productData.meta_keyword} />
                <meta name="author" content={productData.meta_author_name} />
            </Helmet>
            <HeaderPageBanner img={AdsSliderImage1} />
            <Breadcrumbs page1="Products" page2={productData.brand_name} page3={productData.product_name} />
            <div className="CustomContainer mb-5">
                <div className="card">
                    <div className="product-imgs">
                        <div className="img-display">
                            <div className="img-showcase p-5">
                                <img src={initialImg} />
                            </div>
                        </div>
                        <div className="img-select">

                            {
                                productData.images.map((ele, id) => {
                                    return (
                                        <div className={`img-item border ${id === initialId ? `border-2 border-dark` : ""}`} key={id} onClick={() => handleImageChange(ele, id)}>
                                            <img src={`${process.env.REACT_APP_IMAGE_URL}/${ele}`} alt={productData.product_name} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className="product-content">
                        <h2 className="product-title">{productData.product_name}</h2>
                        <a href="#" className="product-link">Brand: {productData.brand_name}</a>
                        {/* <div className="product-rating">
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star"></i>
                            <i className="fa fa-star-half-alt"></i>
                            <span>4.7(21)</span>
                        </div> */}
                        <div className="product-price">
                            <p className="new-price">New Price: <span>{productData.price}</span></p>
                        </div>

                        <div className="product-detail">
                            <h2>Descriptions</h2>
                            <p dangerouslySetInnerHTML={{ __html: productData.description_section1 }}></p>
                        </div>


                        <div className="product-detail">
                            <h2>Specifications</h2>
                            <p dangerouslySetInnerHTML={{ __html: productData.specification_section2 }}></p>
                            {/* <ul>
                                <li>Color: <span>Black</span></li>
                                <li>Available: <span>in stock</span></li>
                                
                                <li>Category: <span>Shoes</span></li>
                                <li>Shipping Area: <span>All over the world</span></li>
                                <li>Shipping Fee: <span>Free</span></li>
                            </ul> */}
                        </div>

                        <div className="social-links">
                            <p>Share At: </p>
                            <FacebookShareButton
                                url={window.location.href}
                                // quote={productData.description_section1}
                                // hashtag={`#${productData.brand_name}`}
                                // description={(productData.description_section1)}
                                className="Demo__some-network__share-button"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={window.location.href}
                                // quote={"フェイスブックはタイトルが付けれるようです"}
                                // hashtag={"#hashtag"}
                                // description={"aiueo"}
                                className="Demo__some-network__share-button"
                                style={{
                                    paddingLeft: "5px"
                                }}
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            <InstapaperShareButton
                                url={window.location.href}
                                // quote={"フェイスブックはタイトルが付けれるようです"}
                                // hashtag={"#hashtag"}
                                // description={"aiueo"}
                                className="Demo__some-network__share-button"
                                style={{
                                    paddingLeft: "5px"
                                }}
                            >
                                <InstapaperIcon size={32} round />
                            </InstapaperShareButton>
                            <WhatsappShareButton
                                url={window.location.href}
                                // quote={"フェイスブックはタイトルが付けれるようです"}
                                // hashtag={"#hashtag"}
                                // description={"aiueo"}
                                className="Demo__some-network__share-button"
                                style={{
                                    paddingLeft: "5px"
                                }}
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            <PinterestShareButton
                                url={window.location.href}
                                // quote={"フェイスブックはタイトルが付けれるようです"}
                                // hashtag={"#hashtag"}
                                // description={"aiueo"}
                                className="Demo__some-network__share-button"
                                style={{
                                    paddingLeft: "5px"
                                }}
                            >
                                <PinterestIcon size={32} round />
                            </PinterestShareButton>
                        </div>
                    </div>
                </div>
            </div>
            <RelatedProduct filterData={filterData} />
            <Subcription />
        </>
    )
}

export default ProductDescription;