import React from 'react'
import "../Assets/css/Breadcrumbs.css"
import { useNavigate } from "react-router-dom";
const Breadcrumbs = ({ page1, page2, page3 }) => {
    const navigate = useNavigate()
    return (
        <div className='breadcrumbContainer mb-5'>
            {/* <h4 style={{
                paddingLeft: "0.6em"
            }}>{page}</h4>
            <div className="breadcrumb flat ">
                <a href="#" className={page === "Hpme" ? "active" : ""}>Home</a>
                <a href="#" className={page === "Products" || page === "Contact Us" ? "active" : ""}>{page}</a>
            </div> */}


            <div className="sub-banner">
                <div className="detail">
                    <div className="CustomContainer">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="paging">
                                    <h4>{page1}</h4>
                                    <ul>
                                        <li style={{ cursor: "pointer" }}><a href="javascript:void(0);" onClick={() => navigate("/")}>Home</a></li>
                                        {page1 === "Products" ? <li style={{ cursor: "pointer" }} onClick={() => navigate("/Products")}><a href="javascript:void(0);">{page1}</a></li> : <li><a>{page1}</a></li>}

                                        {page2 === undefined ? "" : <li><a href="javascript:void(0);">{page2}</a></li>}
                                        {page3 === undefined ? "" : <li><a href="javascript:void(0);">{page3}</a></li>}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Breadcrumbs