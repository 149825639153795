import React, { useEffect, useState } from "react";
import "../Assets/css/Cookie.css"
import { useNavigate } from "react-router-dom";
const Cookie = () => {
    const navigate = useNavigate()
    const [isCookiesShow, setIsCookiesShow] = useState("")


    const executeCodes = () => {
        const showCookie = localStorage.showCookie
        console.log("showCookie");

        if (showCookie === "show") {
            setIsCookiesShow("")
        } else {
            setIsCookiesShow("show")
        }
    };

    const cookiesAccept = () => {
        setIsCookiesShow("")
        localStorage.setItem("showCookie", "show")
    }
    const cookiesDecline = () => {
        setIsCookiesShow("")
    }

    //executeCodes function will be called on webpage load
    useEffect(() => {
        executeCodes()
    }, [])
    return (
        <div className={`cookieWrapper ${isCookiesShow}`}>
            <header>
                <i className="bx bx-cookie"></i>
                <h2>Cookies Consent</h2>
            </header>

            <div className="data">
                <p>This website use cookies to help you have a superior and more relevant browsing experience on the website. <a href="javascript:void(0);" style={{ cursor: "pointer", color: "#ea624c" }} onClick={() => navigate("/cookiePolicy")}> Read more...</a></p>
            </div>

            <div className="buttons">
                <button className="button" id="acceptBtn" onClick={cookiesAccept}>Accept</button>
                <button className="button" id="declineBtn" onClick={cookiesDecline}>Decline</button>
            </div>
        </div>

    )
}

export default Cookie;