import React from 'react';
import { motion } from "framer-motion";
import "../Assets/css/BrandsCards.css";
const BrandsCards = (props) => {
    const { companyData } = props
    console.log("ele.company.link", companyData);
    const openPdf = (link) => {
        const linkOpen = link === null ? "" : window.open(link);
    }
    return (
        <section className='CustomContainer mt-5 mb-5 pb-2'>
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.5 }}
                variants={{
                    hidden: { opacity: 0, y: 30 },
                    visible: { opacity: 1, y: 0 },
                }}
            >
                <h2 className='title text-center pb-3'>Our Trusted Tie-Up Partners: Strengthening the Joyful Journey of Toys!</h2>
                <p className='text-center fs-6 text-secondary pb-4'>Maxwell India takes pride in its strategic partnerships, and our tie-up company is an integral part of our success story. With a shared vision for delivering joy through toys, our collaboration is built on trust, innovation, and a commitment to excellence.</p>
            </motion.div>
            <div className='brandCardStyleContainer px-5'>
                {
                    companyData.map((ele, id) => {
                        return (
                            <div className='brandCardStyle BrandPdftooltip' key={id} onClick={() => openPdf(ele.company.link)}>
                                {ele.company.link === null ? "" : <span className="tooltiptext">Click To Download<span><i className="fa fa-caret-down"></i></span></span>}
                                <img className='' src={`${process.env.REACT_APP_IMAGE_URL}/${ele.company.thumbnail}`} alt={ele.company.thumbnail_alt_tag} />
                            </div>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default BrandsCards;