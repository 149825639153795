import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProductCatalogues from './Pages/ProductCatalogues';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import ProductDescription from './Pages/ProductDescription';
import Terms from './Pages/Terms';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import CookiePolicy from './Pages/CookiePolicy';
import Error from './Pages/Error';
import Faq from './Pages/Faq';
import Loading from './Components/Loading';
import NavbarMain from './Layouts/Navbar';
import Footer from './Layouts/Footer';
import "./Assets/css/color.css"
import "./Assets/css/common.css"
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Helmet } from "react-helmet";
import Cookie from './Components/Cookie';
const App = () => {
  // const state = useSelector((state) => state.routerReducer)
  const [pageLoading, setPageLoading] = useState(true)
  const [homeData, setHomeData] = useState([])
  const [aboutData, setAboutData] = useState([])
  const [companyData, setCompanyData] = useState([])
  const [businesssettingData, setBusinessSetting] = useState([])
  const [websiteSettingData, setWebsiteSetting] = useState([])
  const [allProductData, setAllProductData] = useState([])
  // const [brandsData, setBrandsData] = useState([])
  const [faqData, setFaqData] = useState([])
  const [testimonialData, setTestimonialData] = useState([])
  const [bannerData, setBannerData] = useState([])
  // ============api call==========
  // console.log(websiteSettingData);
  const apiCall = async () => {
    setPageLoading(true)
    // ============Home api call==========
    await axios(`${process.env.REACT_APP_BASE_URL}/home`).then((res) => {
      setHomeData(res.data.data)

    }).catch((error) => {
      console.log(error);
      setPageLoading(true)
    })
    // ============About api call========
    await axios(`${process.env.REACT_APP_BASE_URL}/about`).then((res) => {
      setAboutData(res.data.data)
    }).catch((error) => {
      console.log(error);
      setPageLoading(true)
    })
    // ============Company api call========
    await axios(`${process.env.REACT_APP_BASE_URL}/company`).then((res) => {
      setCompanyData(res.data.data)
    }).catch((error) => {
      console.log(error);
      setPageLoading(true)
    })

    // ============Business setting api call========
    await axios(`${process.env.REACT_APP_BASE_URL}/businesssetting`).then((res) => {
      setBusinessSetting(res.data.data)
    }).catch((error) => {
      console.log(error);
    })

    // ============Websitesetting setting api call========
    await axios(`${process.env.REACT_APP_BASE_URL}/websitesetting`).then((res) => {
      setWebsiteSetting(res.data.data)
    }).catch((error) => {
      console.log(error);
    })

    // ============product setting api call========
    await axios(`${process.env.REACT_APP_BASE_URL}/product`).then((res) => {
      setAllProductData(res.data.data)
    }).catch((error) => {
      console.log(error);
    })

    // ============brand setting api call========
    await axios(`${process.env.REACT_APP_BASE_URL}/brand`).then((res) => {
      // setBrandsData(res.data.data)
    }).catch((error) => {
      console.log(error);
    })

    // ============faq setting api call========
    await axios(`${process.env.REACT_APP_BASE_URL}/faq`).then((res) => {
      setFaqData(res.data.data)
    }).catch((error) => {
      console.log(error);
    })

    // ============testimonial setting api call========
    await axios(`${process.env.REACT_APP_BASE_URL}/testimonial`).then((res) => {
      setTestimonialData(res.data.data)
    }).catch((error) => {
      console.log(error);
    })

    // ============banner setting api call========
    await axios(`${process.env.REACT_APP_BASE_URL}/banner`).then((res) => {
      setBannerData(res.data.data)
    }).catch((error) => {
      console.log(error);
    })











    setPageLoading(false)
  }

  useEffect(() => {
    apiCall()
  }, [])

  return (
    pageLoading ?
      <Loading /> :
      <div className='font-link'>
        <BrowserRouter>
          <Helmet>
            <title>{homeData.banner_details.meta_title}</title>
            <meta name="description" content={homeData.banner_details.meta_description} />
            <meta name="keywords" content={homeData.banner_details.meta_keyword} />
            <meta name="author" content={homeData.banner_details.meta_author_name} />
          </Helmet>
          <NavbarMain businesssettingData={businesssettingData} websiteSettingData={websiteSettingData} />
          <Routes >
            <Route path="/" element={<Home bannerData={bannerData} testimonialData={testimonialData} homeData={homeData} aboutData={aboutData} companyData={companyData} allProductData={allProductData} />} />
            <Route path="/Products" element={<ProductCatalogues companyData={companyData} allProductData={allProductData} page='Products' />} />
            <Route path="/aboutUs" element={<AboutUs page='About Us' companyData={companyData} testimonialData={testimonialData} aboutData={aboutData} pageLoading={pageLoading} allProductData={allProductData} />} />
            <Route path="/contactus" element={<ContactUs page='Contact Us' businesssettingData={businesssettingData} websiteSettingData={websiteSettingData} />} />
            <Route path="/terms" element={<Terms websiteSettingData={websiteSettingData} page='Term & Condition' />} />
            <Route path="/faq" element={<Faq faqData={faqData} page='Faq' />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy page='privacy Policy' websiteSettingData={websiteSettingData} />} />
            <Route path="/cookiePolicy" element={<CookiePolicy websiteSettingData={websiteSettingData} page='Contact Us' />} />
            {
              allProductData.map((route, id) => {
                const result = route.brand_name.replace(/\s/g, '')
                let brandSlug = result.toLowerCase();
                return (
                  <Route key={id} path={`/Products/${brandSlug}/${route.slug}`} element={<ProductDescription page='ProductDescription' ProductData={route} allProductData={allProductData} />} />
                )
              })
            }
            <Route path="*" element={< Error />} />
          </Routes>
          {
            websiteSettingData.cookie_setting.isEnable === 1 ?
              <Cookie />
              :
              ""
          }
          <Footer businesssettingData={businesssettingData} websiteSettingData={websiteSettingData} />
        </BrowserRouter>
      </div>

  )
}

export default App