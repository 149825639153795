import React from 'react'
import Slider from 'react-slick';
import { motion } from "framer-motion"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from 'react-bootstrap';
import "../Assets/css/AllProducts.css";
// import product1 from "../Assets/images/home/products/Masoom_(2).jpg"
import { useNavigate } from 'react-router-dom';
const AllProducts = (props) => {
    const { allProductData } = props
    const navigate = useNavigate()
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 1000,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: true,
        responsive: [
            {
                breakpoint: 629,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },

            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },

        ]
    };

    const handleDiscriptionPage = (val) => {
        // dispatch(routing({ route: "/productdescriptionpage" }))
        const result = val.brand_name.replace(/\s/g, '')
        let brandSlug = result.toLowerCase();
        // console.log("brandSlug", brandSlug);
        // dispatch(routing({ route: "/productdescriptionpage" }))
        navigate(`/Products/${brandSlug}/${val.slug}`, { state: { val } })
    }


    return (
        <Container fluid className='CustomContainer mb-5'>
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.5 }}
                variants={{
                    hidden: { opacity: 0, y: 30 },
                    visible: { opacity: 1, y: 0 },
                }}
            >
                <h2 className='title text-center pb-3'>Magical Moments Await: Explore Maxwell India's Enchanting Toy Products!</h2>
                <p className='text-center fs-6 text-secondary pb-4'>Step into a world of wonder and excitement with Maxwell India's captivating toy Products. Our carefully curated assortment of toys caters to every child's dreams and interests. From educational treasures that ignite young minds to thrilling playtime adventures with action figures and dolls, we have something to enchant every little heart.</p>
            </motion.div>
            <Slider {...settings}>
                {
                    allProductData.map((ele, id) => {
                        return (
                            <div className="cards_item" key={id} onClick={() => handleDiscriptionPage(ele)}>
                                <div className="allProductcard">
                                    <div className="card_image"><img src={`${process.env.REACT_APP_IMAGE_URL}/${ele.thumbnail}`} alt={ele.thumbnail_alt_tag} /></div>
                                    <div className="card_content darkBlue">
                                        <p className="card_title1">{ele.product_name}</p>
                                        <p className="card_title2">{ele.brand_name}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </Slider>
        </Container>
    )
}

export default AllProducts