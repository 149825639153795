import React from "react";
import "../Assets/css/Testimonial.css";
import Slider from 'react-slick';
import { motion } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import IMAGE_URL from "./constants";
const Testimonial = (props) => {
    const { testimonialData } = props
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        slidesToShow: testimonialData.length >= 4 ? 4 : 1,
        slidesToScroll: testimonialData.length >= 4 ? 4 : 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 629,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },

            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },

        ]
    };
    return (

        <div className="CustomContainer marginTopBottom">
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.5 }}
                variants={{
                    hidden: { opacity: 0, y: 30 },
                    visible: { opacity: 1, y: 0 },
                }}
                className="testimonialsTitleDescription"
            >
                <h2 className='title'>Happy Customers, Happy Hearts: <br /> What People Say About Maxwell India!</h2>
                <h2 className='title'>Say about us</h2>
                <p className=' fs-6 text-secondary py-3'>Don't just take our word for it – hear it straight from our valued customers! Maxwell India takes immense pride in delighting children and parents alike with our top-quality toys and exceptional services.</p>
            </motion.div>
            <Slider {...settings} className="overHide">

                {
                    testimonialData.map((ele, id) => {
                        return (
                            <section className="testimonials" key={id}>
                                <div className="testimonialsInner">
                                    <div className="owl-carousel owl-theme testi">
                                        <div className="item">
                                            <div className="profile">
                                                <img src={ele.icon !== null ? `${IMAGE_URL}/${ele.icon}` : "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhHaQdd9mUANh7_JhLInDbMAf0HTWxUBHq5Jc8kM5wyykiDPaIle9-X-cjbV_IH1NyYOcAa1zzTMO1sKt_4uyOSxXgxHDXx5kbo4mnhtLcu0zNiOENr-csyKf8i8ouO8_LsDrhF6h178kUQFlfXct11KfNgfEGUPYCPXOlZ0766t1Ly8tqR6aVPROTW/s1600/img_avatar-removebg-preview%20%281%29.png"} alt={ele.icon_alt_tag} />
                                                <div className="information">
                                                    <div className="stars">
                                                        {
                                                            [...Array(Number(ele.star))].map((ele, id) => {
                                                                return (
                                                                    <i className="fa fa-star" key={id}></i>
                                                                )
                                                            })
                                                        }

                                                        {/* <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i>
                                                        <i className="fa fa-star"></i> */}
                                                    </div>
                                                    <p>{ele.name}</p>
                                                    <span>{ele.designation}</span>
                                                </div>
                                            </div>
                                            <p className=' fs-6 text-secondary py-3' dangerouslySetInnerHTML={{ __html: ele.comment }}></p>
                                            <div className="icon">
                                                <i className="fa fa-quote-right" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        )
                    })
                }
            </Slider>
        </div>

    )
}

export default Testimonial;