import React, { useState, useRef, useEffect } from "react";
import "../Assets/css/Faq.css"
import AdsSliderImage1 from '../Assets/images/home/FAQ.jpg';
import HeaderPageBanner from "../Components/HeaderPageBanner";
import Breadcrumbs from "../Components/Breadcrumbs";
const AccordionItem = (props) => {
    const contentEl = useRef();
    const { handleToggle, active, faq } = props;
    const { question, id, answer } = faq;

    return (
        <div className="rc-accordion-card">
            <div className="rc-accordion-header">
                <div className={`rc-accordion-toggle p-3 ${active === id ? 'active' : ''}`} onClick={() => handleToggle(id)}>
                    <h5 className="rc-accordion-title" dangerouslySetInnerHTML={{ __html: question }}></h5>
                    <i className="fa fa-chevron-down rc-accordion-icon"></i>
                </div>
            </div>
            <div ref={contentEl} className={`rc-collapse ${active === id ? 'show' : ''}`} style={
                active === id
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }
            }>
                <div className="rc-accordion-body">
                    <p className='mb-0' dangerouslySetInnerHTML={{ __html: answer }}></p>
                </div>
            </div>
        </div>
    )
}

const Faq = (props) => {
    const { faqData } = props;
    const [active, setActive] = useState(null);

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    }

    const { websiteSettingData } = props
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    return (
        <>
            <HeaderPageBanner img={AdsSliderImage1} />
            <Breadcrumbs page1={"Faq"} />
            <div className="CustomContainer mt-5 mb-5 ">
                <div className="row justify-content-center ">
                    <div className="col-md-12 mt-2">
                        <div className="card-body">
                            <h4 className="form-heading mb-4 text-primary text-center mt-3">FAQ</h4>
                            {faqData.map((faq, index) => {
                                return (
                                    <AccordionItem key={index} active={active} handleToggle={handleToggle} faq={faq} />
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Faq;
