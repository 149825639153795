import React from "react";
import Breadcrumbs from "../Components/Breadcrumbs";
import SliderHero from "../Components/SliderHero";
import BrandsCards from "../Components/BrandsCards";
import ProductFilter from "../Components/ProductFilter";
import Subcription from "../Layouts/Subcription"
import AdsSliderImage2 from '../Assets/images/home/Home_BG_3.jpg'
import HeaderPageBanner from "../Components/HeaderPageBanner";
import { Helmet } from "react-helmet";
const ProductCatalogues = (props) => {
    const { companyData, allProductData } = props
    return (
        <div>
            <Helmet>
                <title>Products | MAXWELL (INDIA) is one of the oldest and reputed distributors of Toys and Games in Kolkata</title>
                <meta name="description" content="MAXWELL (INDIA) is one of the oldest and reputed distributors of Toys and Games in Kolkata." />
            </Helmet>
            {/* <HeaderPageBanner img={AdsSliderImage2} /> */}
            {/* <Breadcrumbs page1="Products" /> */}
            <BrandsCards companyData={companyData} />
            <ProductFilter companyData={companyData} allProductData={allProductData} />
            <Subcription />
        </div>
    )
}

export default ProductCatalogues;