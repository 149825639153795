import React from 'react'
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Assets/css/AdsSlider.css"
// import AdsSliderImage1 from '../Assets/images/home/homepagebanner1.jpg'
// import AdsSliderImage2 from '../Assets/images/home/homepage_banner2.jpg'
// import AdsSliderImage3 from '../Assets/images/home/homepage_banner3.jpg'
import ads1 from "../Assets/images/home/Ads/ads1.webp"
// import CVR_Pic from "../Assets/images/home/Ads/CVR_Pic.png"

const AdsSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };
    const adsSliderData = [
        {
            id: 1,
            img: ads1
        },
        // {
        //     id: 2,
        //     img: CVR_Pic
        // },
        // {
        //     id: 3,
        //     img: AdsSliderImage3
        // },
        // {
        //     id: 4,
        //     img: AdsSliderImage1
        // },
        // {
        //     id: 5,
        //     img: AdsSliderImage2
        // },
        // {
        //     id: 6,
        //     img: AdsSliderImage3
        // },
    ]
    return (
        <div className='marginTopBottom CustomContainer'>
            <Slider {...settings}>
                {
                    adsSliderData.map(({ img, id }) => {
                        return (
                            <div className='adsSliderStyle' key={id}>
                                <img className='adsSliderStyleImg' src={img} alt={img} />
                            </div>
                        )
                    })
                }
            </Slider>
        </div>
    )
}

export default AdsSlider