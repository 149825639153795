import React from 'react'
// import Slider from 'react-slick';
import { motion } from "framer-motion"
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { Container } from 'react-bootstrap';
import "../Assets/css/AllProducts.css";
import product1 from "../Assets/images/home/products/Masoom_(2).jpg";
import IMAGE_URL from './constants';
import { useNavigate } from 'react-router-dom';

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, EffectCube } from 'swiper/modules';
import 'swiper/css';
const RelatedProduct = (props) => {
    const navigate = useNavigate()
    const { filterData } = props
    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 4,
    //     slidesToScroll: 4,
    //     arrows: true,
    //     responsive: [
    //         {
    //             breakpoint: 629,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //             }
    //         },

    //         {
    //             breakpoint: 1400,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1
    //             }
    //         },

    //     ]
    // };
    const handleDiscriptionPage = (val) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        const result = val.brand_name.replace(/\s/g, '')
        let brandSlug = result.toLowerCase();
        // console.log("brandSlug", brandSlug);
        // dispatch(routing({ route: "/productdescriptionpage" }))
        navigate(`/Products/${brandSlug}/${val.slug}`, { state: { val } })
    }
    return (
        <Container fluid className='CustomContainer mb-5'>
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.5 }}
                variants={{
                    hidden: { opacity: 0, y: 30 },
                    visible: { opacity: 1, y: 0 },
                }}
            >
                <h2 className='title text-center pb-3'>Related Toyes</h2>
                <p className='text-center fs-6 text-secondary pb-4'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque delectus, distinctio asperiores blanditiis saepe vero repudiandae, ullam modi harum a esse veniam dignissimos mollitia dicta, non at unde quod cum corrupti quae minima? At suscipit voluptate minus ducimus voluptatibus nostrum.</p>
            </motion.div>
            {
                filterData.length > 0 ?
                    // <Slider {...settings}>
                    //     {
                    //         filterData && filterData.map((ele, id) => {
                    //             return (
                    //                 <div className="cards_item" key={id} onClick={() => handleDiscriptionPage(ele)}>
                    //                     <div className="allProductcard">
                    //                         <div className="card_image"><img src={`${IMAGE_URL}/${ele.thumbnail}`} alt={ele.thumbnail_alt_tag} /></div>
                    //                         <div className="card_content">
                    //                             <h2 className="card_title1 p-1">{ele.product_name}</h2>
                    //                             <h3 className="card_title2">{ele.brand_name}</h3>
                    //                         </div>
                    //                     </div>
                    //                 </div>
                    //             )
                    //         })
                    //     }
                    // </Slider>
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y, EffectCube]}
                        spaceBetween={50}
                        slidesPerView={3}
                        navigation
                        scrollbar={{ draggable: true }}
                        breakpoints={{
                            140: {
                                slidesPerView: 1,
                            },
                            240: {
                                slidesPerView: 1,
                            },
                            340: {
                                slidesPerView: 1,
                            },
                            440: {
                                slidesPerView: 1,
                            },
                            540: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 4,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 50,
                            },
                        }}
                    >
                        {filterData && filterData.map((ele, id) => (
                            <SwiperSlide key={id}>
                                <div className="" onClick={() => {
                                    handleDiscriptionPage(ele)
                                }}>
                                    <div className="allProductcard" style={{ cursor: "pointer" }}>
                                        <div className="card_image"><img src={`${IMAGE_URL}/${ele.thumbnail}`} alt={ele.thumbnail_alt_tag} /></div>
                                        <div className="Related_Product_card_content">
                                            <h2 className="card_title1 pb-1">{ele.product_name}</h2>
                                            <h3 className="card_title2">{ele.brand_name}</h3>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    :
                    ""
            }

        </Container>
    )
}

export default RelatedProduct