import React from "react";
import Lottie from "lottie-react";
import loading_animation from "../Assets/lottie/loading_animation.json"
const Loading = () => {
    return (
        <div style={{
            width: "100%",
            height: "100vh",
            position: "relative",
            backgroundColor: "#FFFFFF"
        }}>
            <Lottie style={{
                width: 300,
                height: 300,
                position: "absolute",
                top: "40%",
                left: "50%",
                transform: "translate(-50%,-50%)"
            }} animationData={loading_animation} loop={true} />
        </div>
    )
}
export default Loading;