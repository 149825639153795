import React, { useEffect } from "react";
import AdsSliderImage1 from '../Assets/images/home/T&C.jpg';
import HeaderPageBanner from "../Components/HeaderPageBanner";
import Breadcrumbs from "../Components/Breadcrumbs";

const PrivacyPolicy = (props) => {
    const { websiteSettingData } = props
    // console.log("websiteSettingData: ", websiteSettingData);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])
    return (
        <>
            <HeaderPageBanner img={AdsSliderImage1} />
            <Breadcrumbs page1={"Privacy Policy"} />
            <div className="CustomContainer marginTopBottom">
                <h2>Privacy Policy</h2>
                <p dangerouslySetInnerHTML={{ __html: websiteSettingData && websiteSettingData.privacy_policy.privacy_policy }}></p>
            </div>
        </>
    )
}

export default PrivacyPolicy;