import React from "react";
import "../Assets/css/Footer.css";
import { useNavigate } from "react-router-dom";
import { BsMap, BsEnvelope, BsTelephone, BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";


const Footer = (props) => {
  const navigate = useNavigate()
  const { businesssettingData } = props
  // ==================api call================

  // const [data, setData] = useState({})


  // ==================End api call================
  return (
    <div className="mailfooter">
      <div className="container">
        <div className="d-flex justify-content-between col-12 flex-wrap">
          <div className="col-lg-4 col-md-8 p-3">
            <h5 className="footerheading">ABOUT</h5>
            <div className="d-flex">
              <p className="footerhrone"></p>
              <p className="footerhrtwo"></p>
            </div>


            <img src={`${process.env.REACT_APP_IMAGE_URL}/${businesssettingData.footer_logo}`} className="w-25 mb-3" alt="footerlogo" />

            <p dangerouslySetInnerHTML={{ __html: businesssettingData.about_business }}>
            </p>

            <h6 className="text-uppercase">Follow Us at</h6>
            <div className="d-flex align-items-center justify-content-between w-75 ">
              <div className="Footer-social-links">
                <p>Follow us: </p>
                {
                  businesssettingData.isFacebook === null ? ""
                    :
                    <a href={businesssettingData.facebook_url}>
                      <i className="fa fa-facebook-f"></i>
                    </a>
                }
                {
                  businesssettingData.isLinkedin === null ? ""
                    :
                    <a href={businesssettingData.linkedin_url}>
                      <i className="fa fa-linkedin"></i>
                    </a>
                }
                {
                  businesssettingData.isInstagram === null ? ""
                    :
                    <a href={businesssettingData.instagram_url}>
                      <i className="fa fa-instagram"></i>
                    </a>
                }
                {
                  businesssettingData.isPinterest === null ? ""
                    :

                    <a href={businesssettingData.pinterest_url}>
                      <i className="fa fa-pinterest"></i>
                    </a>
                }

              </div>
            </div>
          </div>

          <div className="col-lg-2 col-md-4 p-3">
            <h5 className="footerheading">QUICK LINKS</h5>
            <div className="d-flex">
              <p className="footerhr"></p>
              <p className="footerhrtwo"></p>
            </div>
            <div className="footerquick">
              <div className="d-flex">
                <p>
                  <BsArrowRight className="footerrighticon" />
                </p>
                <Link to='/' className="text-decoration-none"> <p className="quickname">Home</p></Link>
              </div>
              <div className="d-flex">
                <p>
                  <BsArrowRight className="footerrighticon" />
                </p>
                <Link to='/Products' className="text-decoration-none"><p className="quickname">Products</p> </Link>
              </div>
              <div className="d-flex">
                <p>
                  <BsArrowRight className="footerrighticon" />
                </p>
                <Link to='/aboutUs' className="text-decoration-none"><p className="quickname">About Us</p> </Link>
              </div>
              <div className="d-flex">
                <p>
                  <BsArrowRight className="footerrighticon" />
                </p>
                <Link to='/contactUs' className="text-decoration-none"><p className="quickname">Contact Us</p> </Link>
              </div>


            </div>
          </div>
          <div className="col-lg-2 col-md-4 p-3">
            <h5 className="footerheading"> LEGAL </h5>
            <div className="d-flex">
              <p className="footerhr"></p>
              <p className="footerhrtwo"></p>
            </div>
            <div className="footerquick">

              <div className="d-flex">
                <p>
                  <BsArrowRight className="footerrighticon" />
                </p>
                <p className="quickname" onClick={() => navigate("/faq")}>Faq</p>
              </div>
              <div className="d-flex">
                <p>
                  <BsArrowRight className="footerrighticon" />
                </p>
                <p className="quickname" onClick={() => navigate("/terms")}>Terms of Use</p>
              </div>
              <div className="d-flex">
                <p>
                  <BsArrowRight className="footerrighticon" />
                </p>
                <p className="quickname" onClick={() => navigate("/privacyPolicy")}>Privacy Policy</p>
              </div>
              <div className="d-flex">
                <p>
                  <BsArrowRight className="footerrighticon" />
                </p>
                <p className="quickname" onClick={() => navigate("/cookiePolicy")}>Cookie Policy</p>
              </div>

            </div>
          </div>
          <div className="col-lg-4 col-md-8 p-3">
            <h5 className="footerheading">CONTACT</h5>
            <div className="d-flex">
              <p className="footerhrone"></p>
              <p className="footerhrtwo"></p>
            </div>

            <div className="d-flex">
              <BsTelephone className="footerphoneicon" />
              <div className="footercontact">
                <p className="footercontacttitle">PHONE</p>
                <p>
                  {" "}
                  <a className="footercall" href={`tel:+91${businesssettingData.business_phone1}`}>
                    +91 {businesssettingData.business_phone1}</a> <a href={`tel:+91${businesssettingData.business_phone3}`}>/ +91 {businesssettingData.business_phone3}</a>

                </p>
              </div>
            </div>
            <hr />
            <div className="d-flex">
              <BsEnvelope className="footermailicon" />
              <div className="footercontact">
                <p className="footercontacttitle">EMAIL</p>
                <p>
                  {" "}
                  <a className="footercall" href={`mailto:${businesssettingData.business_email}`}>
                    {businesssettingData.business_email}
                  </a>
                </p>
              </div>
            </div>
            <hr />
            <div className="d-flex">
              <BsMap className="footeraddicon" />
              <div className="footercontact">
                <p className="footercontacttitle">ADDRESS</p>
                <p className="footercontactadress">
                  <a className="footercall" href="https://www.google.com/maps/place/Maxwell+Toys/@22.576854,88.353712,16z/data=!4m6!3m5!1s0x3a02765321b3ac7b:0xb272ed40580b5f6f!8m2!3d22.5768538!4d88.3537121!16s%2Fg%2F1vq737z8?hl=en&entry=ttu" target="_blank" >
                    {businesssettingData.business_address}
                  </a>

                </p>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="d-flex justify-content-between flex-wrap">
          <div className="d-flex flex-wrap">
            <p>© Copyright 2023 </p>
            <p className="footertwo">  Maxwell Pvt Ltd.</p>
          </div>
          <div className="d-flex flex-wrap">
            <p>©Site Supported By </p>
            <p className="footertwo">
              <a
                className="footertwoa"
                target="/"
                href="https://valktechnologies.com/"
              >
                Valk Technologies and Solutions.
              </a>
            </p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Footer;
