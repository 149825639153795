import React from "react";
import "../Assets/css/PopularItem.css"
import product1 from "../Assets/images/home/products/1_(1).jpg"
import product2 from "../Assets/images/home/products/570-x-510-2.jpg"
import product3 from "../Assets/images/home/products/Brands.jpg"
import product4 from "../Assets/images/home/products/Fun_zoo.jpg"
import product5 from "../Assets/images/home/products/Itoys.jpg"
import product6 from "../Assets/images/home/products/Masoom_(2).jpg"
import product7 from "../Assets/images/home/products/Play_Panda.jpg"
import product8 from "../Assets/images/home/products/ZEPhyr.jpg"
// import product9 from "../Assets/images/home/products/centy_(2).jpg"
// import product10 from "../Assets/images/home/products/explore_(2).jpg"
// import product11 from "../Assets/images/home/products/panda1.jpg"
// import product12 from "../Assets/images/home/products/peacock_(1).jpg"
// import product13 from "../Assets/images/home/products/shinsei_(1).jpg"
import { useNavigate } from "react-router-dom";
import { CiShare1 } from "react-icons/ci";
const PopularItem = () => {
    const navigate = useNavigate()
    const redirectProductPage = () => {
        navigate("/Products")
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    return (
        <div className="CustomContainer paddingTopBottom">
            <h2 className='title text-center pb-5'>Popular Items</h2>
            <div className="grid-wrapper">
                <div className="popularItemCard" onClick={redirectProductPage}>
                    <img src={product1} alt="" />
                    <div className="content content1" >
                        <CiShare1 size={50} color="#FFF" />
                    </div>
                </div>
                <div className="popularItemCard" onClick={redirectProductPage}>
                    <img src={product2} alt="" />
                    <div className="content content2" >
                        <CiShare1 size={50} color="#FFF" />
                    </div>
                </div>
                <div className="tall popularItemCard" onClick={redirectProductPage}>
                    <img src={product3} alt="" />
                    <div className="content content3" >
                        <CiShare1 size={50} color="#FFF" />
                    </div>
                </div>
                <div className="wide popularItemCard" onClick={redirectProductPage}>
                    <img src={product4} alt="" />
                    <div className="content content4" >
                        <CiShare1 size={50} color="#FFF" />
                    </div>
                </div>
                <div className="popularItemCard" onClick={redirectProductPage}>
                    <img src={product5} alt="" />
                    <div className="content content5" >
                        <CiShare1 size={50} color="#FFF" />
                    </div>
                </div>
                <div className="tall popularItemCard" onClick={redirectProductPage}>
                    <img src={product6} alt="" />
                    <div className="content content6" >
                        <CiShare1 size={50} color="#FFF" />
                    </div>
                </div>
                <div className="big popularItemCard" onClick={redirectProductPage}>
                    <img src={product7} alt="" />
                    <div className="content content7" >
                        <CiShare1 size={50} color="#FFF" />
                    </div>
                </div>
                <div className="popularItemCard" onClick={redirectProductPage}>
                    <img src={product8} alt="" />
                    <div className="content content8" >
                        <CiShare1 size={50} color="#FFF" />
                    </div>
                </div>
                <div className="popularItemCard" onClick={redirectProductPage}>
                    <img src={product1} alt="" />
                    <div className="content content9" >
                        <CiShare1 size={50} color="#FFF" />
                    </div>
                </div>
                {/* <div className="wide">
                    <img src={product9} alt="" />
                </div> */}
                {/* <div>
                    <img src={product1} alt="" />
                </div>
                <div className="big">
                    <img src={product10} alt="" />
                </div>
                <div className="tall">
                    <img src={product11} alt="" />
                </div>
                <div>
                    <img src={product12} alt="" />
                </div>
                <div>
                    <img src={product13} alt="" />
                </div> */}
            </div>

        </div>
    )
}
export default PopularItem;