import React, { useEffect } from "react";
import "../Assets/css/Subcription.css"

const Subcription = () => {

    return (
        <div id='subscribe-css' className="CustomContainer mt-5">
            <p className='subscribe-note'><span>SUBSCRIBE</span> TO OUR NEWSLETTER</p>
            <div className='subscribe-wrapper'>
                {/* <div className='subscribe-form'>
                    <form action='https://feedburner.google.com/fb/a/mailverify?uri=PetaKode' className='subscribe-form' method='post' onSubmit='window.open (&apos;https://feedburner.google.com/fb/a/mailverify?uri=PetaKode&apos;, &apos;popupwindow&apos;, &apos;scrollbars=yes,width=550,height=520&apos;);return true' target='popupwindow'>
                        <input name='uri' type='hidden' value='ArlinaDesign' /><input name='loc' type='hidden' value='en_US' /><input autoComplete='off' className='subscribe-css-email-field' name='email' placeholder='Enter your Email' /><input className='subscribe-css-email-button' title='' type='submit' value='submit' /></form>
                </div> */}
                <div className='subscribe-form'>
                    <form className='subscribe-form'>
                        <input name='uri' type='hidden' value='ArlinaDesign' /><input name='loc' type='hidden' value='en_US' /><input autoComplete='off' className='subscribe-css-email-field' name='email' placeholder='Enter your Email' /><input className='subscribe-css-email-button' title='' type='submit' value='submit' /></form>
                </div>
            </div>
        </div>
    )
}

export default Subcription;