import React from 'react'
import '../Assets/css/HeaderPageBanner.css'



const HeaderPageBanner = (props) => {

    return (
        <>
            <div className="HeaderPageBannerContainer">
                <img src={props.img} alt="" />
            </div>
        </>

    )
}

export default HeaderPageBanner