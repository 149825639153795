import React, { useEffect, useState } from "react";
import SliderHero from "../Components/SliderHero";
import BrandsSlider from "../Components/BrandsSlider";
import AdsSlider from "../Components/AdsSlider";
import PopularItem from "../Components/PopularItem";
import ItemCard from "../Components/ItemCard";
import AllProducts from "../Components/AllProducts";
import Testimonial from "../Components/Testimonial";
import Subcription from "../Layouts/Subcription";
import AboutSortDescriptiom from "./AboutSortDescriptiom";
import Loading from "../Components/Loading";
import axios from "axios";
const Home = (props) => {
    const { homeData, aboutData, companyData, allProductData, testimonialData, bannerData } = props;
    return (
        <div>
            <SliderHero homeData={homeData} companyData={companyData} />
            {/* <Breadcrumbs /> */}
            <AboutSortDescriptiom aboutData={aboutData} />
            <BrandsSlider companyData={companyData} />
            <AdsSlider />
            <ItemCard />
            <PopularItem />
            <AllProducts allProductData={allProductData} />
            <Testimonial testimonialData={testimonialData} />
            <Subcription />
        </div>
    )
}

export default Home;