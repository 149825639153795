import React from "react";
import "../Assets/css/ProductCard.css";
import product12 from "../Assets/images/home/products/peacock_(1).jpg"
import product13 from "../Assets/images/home/products/shinsei_(1).jpg"
import { useNavigate } from "react-router-dom";
const ItemCard = () => {
    const navigate = useNavigate()
    return (
        <div className="CustomContainer ProductCardContainer">
            <div className="ProductCard" onClick={() => navigate("/Products/aeroplanes/thunder-streak")}>
                <button className="ProductreadMore">Read More...</button>
                <img className="bg" src={product12} alt="product12" />
            </div>

            <div className="ProductCard">
                <button className="ProductreadMore" onClick={() => navigate("Products/digandbuild/JCT%20Earth%20Mover%20CT-079")}>Read More...</button>
                <img className="bg" src={product13} alt="product13" />
            </div>
        </div>
    )
}

export default ItemCard;