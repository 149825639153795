import React, { useEffect } from "react";
import About_us from "../Assets/images/aboutus/About_us.jpg";
import { useNavigate } from "react-router-dom";
const AboutSortDescriptiom = (props) => {
    const { aboutData } = props
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])
    const navigate = useNavigate()
    return (
        <div className="CustomContainer d-flex flex-column flex-md-column flex-lg-row mx-auto gap-5 my-5 border-0">
            <div
                className=" rounded-0">
                <h2 className='font-bell'>{aboutData.title1}</h2>
                <p className='fs-6 text-secondary' dangerouslySetInnerHTML={{ __html: aboutData.short_description }}></p>
                <button className="readMore" onClick={() => navigate("/AboutUs")}>Read More...</button>
            </div>
            <img src={`${process.env.REACT_APP_IMAGE_URL}/${aboutData.left_image}`} className='cardImage' alt="About_us" />
        </div >
    )
}

export default AboutSortDescriptiom;
