import React, { useState, useRef } from 'react';
import { motion } from "framer-motion";
import "../Assets/css/ProductFilter.css";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
const ProductFilter = (props) => {
    const { companyData, allProductData } = props
    const contentEl = useRef("0px");
    // console.log(contentEl.current.scrollHeight);
    const navigate = useNavigate()

    const handleDiscriptionPage = (val) => {
        const result = val.brand_name.replace(/\s/g, '')
        let brandSlug = result.toLowerCase();
        // console.log("brandSlug", brandSlug);
        // dispatch(routing({ route: "/productdescriptionpage" }))
        navigate(`/Products/${brandSlug}/${val.slug}`, { state: { val } })
    }

    // ===============================

    const [filterData, setFilterData] = useState(allProductData)
    const [activeId, setActiveId] = useState(0)
    const [categoryActive, setCategoryActive] = useState("")
    const sunMenuToggleFun = (id) => {
        setActiveId(id)
    }

    const allProduct = () => {
        setActivePage(1);
        setFilterData(allProductData)
    }

    const filterItemFun = async (val, id) => {
        // console.log("filter Val : ", val);
        setCategoryActive(id)
        setActivePage(1);
        const returnFilterData = allProductData.filter(ele => ele.brand_name === val)
        // console.log("returnFilterData: ", returnFilterData);
        setFilterData(returnFilterData)
    }

    // ================pagination==========

    const allProductlength = filterData.length
    const [activePage, setActivePage] = useState(1)
    const dataPerPage = 12
    // Get current posts
    const indexOfLastData = activePage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    const currentData = filterData.slice(indexOfFirstData, indexOfLastData);
    const handlePageChange = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setActivePage(pageNumber);
    }


    return (
        <div className="CustomContainer">
            <div className='productFilterContainer'>
                <div className='productFilterContainerLeft pt-3'>
                    <div className="productFilter productSearch">
                        <form className="search-form">
                            <div className="form-group1">
                                <input className="form-control" type="text" placeholder="Search" />
                            </div>
                            <div className="form-group2">
                                <button type="submit" className="btn btn-block btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                    <div className="productFilter dropdownFilter">
                        <div className='procedures-links-container'>
                            <div className="procedures-links">
                                <ul id="procedures-links" className="accordion" >
                                    <li className="open" onClick={allProduct}>
                                        <div className="link" style={{ backgroundColor: "#071952" }}>All Products<i style={{ color: "#FFF" }} className="fa fa-home"></i></div>
                                    </li>

                                    {
                                        companyData.map((ele, id) => {
                                            return (
                                                <li className="open" key={id} onClick={() => sunMenuToggleFun(id)} >
                                                    <div className="link">{ele.company.company_name}<i className="icon-chevron-down"></i></div>
                                                    <ul className={`submenu ${id === activeId ? "slideUp" : ""}`} style={
                                                        id === activeId
                                                            ? { height: contentEl.current.scrollHeight * ele.brand_name.length, }
                                                            : { height: "0px" }
                                                    }>
                                                        {
                                                            ele.brand_name.map((ele, id) => {
                                                                return (
                                                                    <React.Fragment key={id}>
                                                                        <li onClick={() => filterItemFun(ele, id)} style={
                                                                            id === categoryActive ? { backgroundColor: "#FFF", color: "#02adc6" } : { backgroundColor: "#ea624c" }
                                                                        }>
                                                                            <a href="javascript:void(0);" style={id === categoryActive ? { cursor: "pointer", color: "#02adc6" } : { cursor: "pointer" }} ref={contentEl}>{ele}</a>
                                                                        </li>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
                <div className='productFilterOuterContainerRight'>
                    <div className='productFilterContainerRight'>
                        {
                            currentData.length > 0 ?
                                currentData.map((ele, id) => {
                                    return (
                                        <motion.div className="cards_item" key={id} onClick={() => handleDiscriptionPage(ele)}
                                            initial="hidden"
                                            whileInView="visible"
                                            viewport={{ once: true, amount: 0.5 }}
                                            transition={{ duration: 0.5, delay: 0.1 * id }}
                                            variants={{
                                                hidden: { opacity: 0, y: 30 },
                                                visible: { opacity: 1, y: 0 },
                                            }}
                                        >
                                            <div className="allProductcard">
                                                <div className="card_image"><img src={`${process.env.REACT_APP_IMAGE_URL}/${ele.thumbnail}`} alt={ele.thumbnail_alt_tag} /></div>
                                                <div className="card_content darkBlue">
                                                    <p className="card_title1">{ele.product_name}</p>
                                                    <p className="card_title2">{ele.brand_name}</p>
                                                </div>
                                            </div>
                                        </motion.div>
                                    )

                                }) :
                                <div className="dataNotfoundStyle">
                                    <h6>COMING SOON</h6>
                                </div>
                        }
                    </div>
                    <div className='PaginationContainer'>
                        {allProductlength > 12 ?
                            <Pagination
                                activePage={activePage}
                                activeLinkclassName='activeStyle'
                                activeclassName="active-item"
                                itemsCountPerPage={1}
                                totalItemsCount={allProductlength / 12}
                                pageRangeDisplayed={3}
                                onChange={(e) => handlePageChange(e)}
                                itemclassName="page-item"
                                linkclassName="page-link"
                            /> : ""
                        }

                    </div>
                </div>

            </div>
        </div >
    )
}
export default ProductFilter;