import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import '../Assets/css/Navbar.css';
import { BiMenu, } from "react-icons/bi";
import { MdLocationOn } from "react-icons/md";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { BsXLg, BsWhatsapp } from "react-icons/bs";
import { motion } from "framer-motion";
// import axios from "axios";
const Navbar = (props) => {
  const { businesssettingData, websiteSettingData } = props;
  // console.log("businesssettingData: ", businesssettingData);

  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  const handleClose = () => setNav(!nav);

  // ==================api call================



  // ==================End api call================

  return (
    <>
      <div className="top-bar CustomContainer">
        <div className="row">
          <div className="col-md-6 d-flex">
            <div className="get-touch">
              <ul>
                <li className="d-flex">
                  <MdLocationOn className="address addressMain" color="#fff" />
                  <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Maxwell+Toys/@22.576854,88.353712,16z/data=!4m6!3m5!1s0x3a02765321b3ac7b:0xb272ed40580b5f6f!8m2!3d22.5768538!4d88.3537121!16s%2Fg%2F1vq737z8?hl=en&entry=ttu">
                    {businesssettingData.business_address}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="get-touch">
              <ul>
                <li className="mailStyle"><a href={`mailto: ${businesssettingData.business_email}`}><AiOutlineMail className="address " color="#fff" /> {businesssettingData.business_email}</a></li>
                <li><a href={`tel:+91${businesssettingData.business_phone1}`}><AiOutlinePhone className="address" color="#fff" /> +91 {businesssettingData.business_phone1}</a><a href={`tel:+91${businesssettingData.business_phone3}`}><AiOutlinePhone className="address" color="#fff" />+91{businesssettingData.business_phone3}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>


      {/* Navbar */}
      <nav className='navbar w-100 z-3 colorwhite CustomContainer'>
        <div className="px-2 d-flex align-items-center w-100 h-100 ">
          <Link to='/'><img src={`${process.env.REACT_APP_IMAGE_URL}/${businesssettingData.navbar_logo}`} className='logo' alt="logo" /></Link>
          <div className="d-flex align-items-center mx-auto pt-3">

            <ul className=" d-none d-lg-flex gap-5 align-items-center mx-auto list-unstyled text-decoration-none">
              <NavLink to="/" className="text-decoration-none active">
                <li className="text-center fw-bolder navbarMenuText fs-6">
                  <span>Home</span>
                </li>
              </NavLink>

              {/* <NavLink to="/about" >
                <li className="text-center fw-bold ">
                  <span>About</span>
                </li>
              </NavLink> */}

              <NavLink to="/Products" className="text-decoration-none">
                <li className="text-center fw-bold navbarMenuText fs-6">
                  <span>Products</span>
                </li>
              </NavLink>

              <NavLink to="/aboutUs" className="text-decoration-none">
                <li className="text-center fw-bold navbarMenuText fs-6">
                  <span>About Us</span>
                </li>
              </NavLink>
              <NavLink to="/contactUs" className="text-decoration-none">
                <li className="text-center fw-bold navbarMenuText fs-6">
                  <span>Contact Us</span>
                </li>
              </NavLink>
              <NavLink to="https://wa.me/7439388935" target="_blank" className="text-decoration-none">
                <div className="bavbarBtn"><BsWhatsapp className="pb-1" size={20} />&nbsp;Click To Chat</div>
              </NavLink>
            </ul>
          </div>

          <div className="d-lg-none me-4 " onClick={handleClick}>
            {!nav ? <BiMenu size={40} color="#000" /> : <BsXLg size={40} color="#000" />}
          </div>
        </div>

        <motion.ul
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: -20 },
            visible: { opacity: 1, y: 0 },
          }}
          className={!nav ? "d-none" : "navShow"}>

          <motion.li
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 1 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, x: -20 },
              visible: { opacity: 1, x: 0 },
            }}
            className="border-bottom border-2 py-2 w-100 ">
            <NavLink
              onClick={handleClose}
              to="/"
              className="text-decoration-none navbarMenuText"

            >
              <span>
              </span> Home
            </NavLink>
          </motion.li>

          <motion.li
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 1 }}
            transition={{ duration: 0.5, delay: 0.15 }}
            variants={{
              hidden: { opacity: 0, x: -20 },
              visible: { opacity: 1, x: 0 },
            }}

            className="border-bottom border-2 py-2 w-full ">
            <NavLink onClick={handleClose} to="/Products" className="text-decoration-none navbarMenuText">
              Products
            </NavLink>
          </motion.li>

          <motion.li
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 1 }}
            transition={{ duration: 0.5, delay: 0.15 }}
            variants={{
              hidden: { opacity: 0, x: -20 },
              visible: { opacity: 1, x: 0 },
            }}

            className="border-bottom border-2 py-2 w-full ">
            <NavLink onClick={handleClose} to="/aboutUs" className="text-decoration-none navbarMenuText">
              About Us
            </NavLink>
          </motion.li>

          <motion.li
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            variants={{
              hidden: { opacity: 0, x: -20 },
              visible: { opacity: 1, x: 0 },
            }}
            className="border-bottom border-2 py-2 w-full " >
            <NavLink
              onClick={handleClose}
              to="/contactUs"
              className="text-decoration-none navbarMenuText"

            >
              Contact Us
            </NavLink>
          </motion.li>


          {/* <motion.li
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 1 }}
            transition={{ duration: 0.5, delay: 0.25 }}
            variants={{
              hidden: { opacity: 0, x: -20 },
              visible: { opacity: 1, x: 0 },
            }}
            className="border-bottom border-2 py-2 w-full">
            <NavLink
              onClick={handleClose}
              to="/ourProducts"
              className="text-decoration-none navbarMenuText"

            >
              Our Products
            </NavLink>
          </motion.li> */}

          {/* <motion.li
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 1 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            variants={{
              hidden: { opacity: 0, x: -20 },
              visible: { opacity: 1, x: 0 },
            }}
            className="border-bottom border-2 py-2 w-full">
            <NavLink
              onClick={handleClose}
              to="/whatsnew"
            >
              What's New
            </NavLink>
          </motion.li> */}

          {/* <motion.li
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 1 }}
            transition={{ duration: 0.5, delay: 0.35 }}
            variants={{
              hidden: { opacity: 0, x: -20 },
              visible: { opacity: 1, x: 0 },
            }}
            className="border-bottom border-2 py-2 w-full">
            <NavLink
              onClick={handleClose}
              to="/career"
              className="text-decoration-none navbarMenuText"
            >
              Career
            </NavLink>
          </motion.li>

          <motion.li
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            variants={{
              hidden: { opacity: 0, x: -20 },
              visible: { opacity: 1, x: 0 },
            }}
            className="border-bottom border-2 py-2 w-full">
            <NavLink
              onClick={handleClose}
              to="/contactus"
              className="text-decoration-none navbarMenuText"
            >
              Contact Us
            </NavLink>
          </motion.li> */}

        </motion.ul>

      </nav>


    </>
  );
};

export default Navbar;
