import React from 'react'
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion"
import "../Assets/css/BrandsSlider.css"
// import sliderImage1 from '../Assets/images/home/brands/Masoom.png'
// import sliderImage2 from '../Assets/images/home/brands/Peacock.png'
// import sliderImage3 from '../Assets/images/home/brands/Shinsei.png'
// import sliderImage4 from '../Assets/images/home/brands/panda_Baby_Product.png'

const BrandsSlider = (props) => {
    const { companyData } = props
    const openPdf = (link) => {
        const linkOpen = link === null ? "" : window.open(link);
    }
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 1500,
        slidesToShow: 7,
        slidesToScroll: 7,
        arrows: false,
        centerPadding: "60px",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                dots: false,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: false,
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    return (
        <section className='CustomContainer'>
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.5 }}
                variants={{
                    hidden: { opacity: 0, y: 30 },
                    visible: { opacity: 1, y: 0 },
                }}
            >
                <h2 className='title text-center pb-3'>Our Trusted Tie-Up Partners: Strengthening the Joyful Journey of Toys!</h2>
                <p className='text-center fs-6 text-secondary pb-4'>Maxwell India takes pride in its strategic partnerships, and our tie-up company is an integral part of our success story. With a shared vision for delivering joy through toys, our collaboration is built on trust, innovation, and a commitment to excellence.</p>
            </motion.div>
            <Slider {...settings} className=''>
                {
                    companyData && companyData.map((ele, id) => {
                        return (
                            <div className='brandSliderStyle Pdftooltip' key={id} onClick={() => openPdf(ele.company.link)}>
                                {ele.company.link === null ? "" : <span className="tooltiptext">Click To Download<span><i className="fa fa-caret-down"></i></span></span>}
                                <img className='brandSliderStyleImg' src={`${process.env.REACT_APP_IMAGE_URL}/${ele.company.thumbnail}`} alt={ele.company.thumbnail_alt_tag} />
                            </div>
                        )
                    })
                }
            </Slider>
        </section>
    )
}

export default BrandsSlider