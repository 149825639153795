import React, { useEffect } from 'react'

import Lottie from 'react-lottie';
// import animationData from '../Utils/animation.json';

// import '../Assets/Css/Error.css'
import { Link, useNavigate } from 'react-router-dom';

const Error = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0 });
    }, []);
    const navigate = useNavigate()

    // const defaultOptions = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: animationData,
    // };
    return (
        <div>
            <div className="d-flex align-items-center justify-content-center pb-5">
                <div className="errorContainer text-center row d-flex flex-row align-items-center rounded-5 py-3 mt-5">

                    <div className=" col-md-6 mt-5 ">
                        <p className="fs-1 fw-bold"> YOU SEEM TO BE LOST!</p>
                        <p className="lead fs-5 fw-semibold">
                            The page you’re looking for doesn’t exist.
                        </p>
                        <div className='d-flex mx-auto text-center justify-content-center gap-5 my-3'>

                            <Link to="/" className="btn btn-light w-25 py-3 fw-bold">Go Home</Link>
                            <button onClick={() => navigate(-1)} className="btn btn-dark w-25 py-3 fw-bold ">Go Back</button>
                        </div>

                    </div>

                    <div className="col-md-6 ">
                        {/* <Lottie
                            options={defaultOptions}
                            height={'70%'}
                            width={'70%'}
                        /> */}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Error