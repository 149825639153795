import { ROUTING } from "../types/actionType";

const initialAuthState = {
    route: ""
}

const routerReducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case ROUTING:
            return {
                ...state,
                route: action.data
            }
        default:
            return state;
    }
}

export default routerReducer;