import React, { useState, useEffect } from "react";
import axios from "axios";
import "../Assets/css/AboutUs.css";
import Breadcrumbs from "../Components/Breadcrumbs";
import HeaderPageBanner from "../Components/HeaderPageBanner";
import AllProducts from "../Components/AllProducts";
import BrandsSlider from "../Components/BrandsSlider";
import Testimonial from "../Components/Testimonial";
import Subcription from "../Layouts/Subcription";
import About_us from "../Assets/images/aboutus/About_us.jpg";
import AdsSliderImage1 from '../Assets/images/home/Home_BG_4.jpg';
import { Helmet } from "react-helmet";
import Loading from "../Components/Loading";
const AboutUs = (props) => {
    const { aboutData, companyData, pageLoading, allProductData, testimonialData } = props;
    // console.log("aboutData", aboutData);
    return (
        pageLoading ?
            <Loading /> :
            <>
                <Helmet>
                    <title>About Us | {aboutData.meta_title}</title>
                    <meta name="description" content={aboutData.meta_description} />
                    <meta name="keywords" content={aboutData.meta_keyword} />
                    <meta name="author" content={aboutData.meta_author_name} />
                </Helmet>
                <HeaderPageBanner img={AdsSliderImage1} />
                <Breadcrumbs page1={"About Us"} />
                <section id="about" className="about CustomContainer pt-2 pb-5">
                    <div className="">
                        <div className="row">
                            <div className="col-xl-5 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative">
                                <img src={`${process.env.REACT_APP_IMAGE_URL}/${aboutData.left_image}`} className='cardImage' alt="About_us" />
                            </div>

                            <div className="col-xl-7 col-lg-6 icon-boxes d-flex flex-column px-lg-5">
                                <h2>{aboutData.title1}</h2>
                                <p dangerouslySetInnerHTML={{ __html: aboutData.long_description }}></p>
                            </div>
                        </div>
                    </div>
                    <article className="mt-5 aboutArticleOne">
                        <h3 style={{ fontWeight: "bold" }}>{aboutData.title2}</h3>
                        <p dangerouslySetInnerHTML={{ __html: aboutData.aim_description }}></p>
                    </article>
                    <article className="mt-5 aboutArticleTwo">
                        <h3 style={{ fontWeight: "bold" }}>{aboutData.title3}</h3>
                        <p dangerouslySetInnerHTML={{ __html: aboutData.quality_description }}></p>
                    </article>
                </section>
                <BrandsSlider companyData={companyData} />
                <div className="mt-5">
                    <AllProducts allProductData={allProductData} />
                </div>
                <Testimonial testimonialData={testimonialData} />
                <Subcription />
            </>
    )
}

export default AboutUs;